export const theme = {
  name: 'roxana',
  // customizations
  // tokens: {
  //   colors: {
  //     font: {
  //       secondary: { value: 'hotpink' }
  //     }
  //   }
  // }
}
